import React, { useState, useEffect } from "react";
import {
  Input,
  Container,
  Row,
  Col,
  Label,
  FormGroup,
  Button,
} from "reactstrap";
import "../home.css";
import Navbar from "../Navbar/Navbars";
import axios from "axios";
const Home = () => {
  const [message1, setMessage1] = useState("");

  const sendmesage1 = async () => {
    await axios.post(`https://back-housekk1.herokuapp.com/register-notify/6`, {
      message: message1,
    });
    setMessage1("");
  };

  const send1 = () => {
    axios.post(`https://back-housekk1.herokuapp.com/register-notify/1`);
  };

  const send3 = () => {
    axios.post(`https://back-housekk1.herokuapp.com/register-notify/3`);
  };

  const send4 = () => {
    axios.post(`https://back-housekk1.herokuapp.com/register-notify/4`);
  };

  const send5 = () => {
    axios.post(`https://back-housekk1.herokuapp.com/register-notify/5`);
  };

  return (
    <div>
      <Navbar />
      <Container
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          borderRadius: "40px",
        }}
      >
        <h1 className="textHead" style={{ marginBottom: "20px" }}>
          ควบคุมห้องสมัคร ฝาก-ถอน
        </h1>
        <Label
          style={{
            fontSize: "18px",
            color: "white",
            fontWeight: "bolder",
          }}
        >
          ขั้นตอน
        </Label>
        <Row>
          <Button
            color="warning"
            style={{ margin: "10px", border: "2px solid #e7e7e7 " }}
            onClick={send1}
          >
            ให้เแอดบอทเป็นเพื่อน
          </Button>
          <Button
            color="dark"
            style={{ margin: "10px", border: "2px solid #e7e7e7 " }}
            onClick={send3}
          >
            การสมัคร
          </Button>
          <Button
            color="primary"
            style={{ margin: "10px", border: "2px solid #e7e7e7 " }}
            onClick={send4}
          >
            การฝาก
          </Button>
          <Button
            color="danger"
            style={{ margin: "10px", border: "2px solid #e7e7e7 " }}
            onClick={send5}
          >
            การถอน
          </Button>
        </Row>
        <FormGroup row>
          <Label
            className="textSub"
            style={{ fontSize: "18px", marginTop: "20px" }}
          >
            ข้อความ
          </Label>
          <Col>
            <Input
              style={{ width: "300px", marginTop: "20px" }}
              value={message1}
              placeholder=" ข้อความที่ต้องส่งไปยังกลุ่ม "
              onChange={(e) => setMessage1(e.target.value)}
            ></Input>
            <Button
              style={{
                marginTop: "25px",
                width: "100px",
                border: "2px solid #e7e7e7 ",
                marginBottom: "30px",
              }}
              color="success"
              onClick={sendmesage1}
            >
              ส่ง
            </Button>
          </Col>
        </FormGroup>
      </Container>
    </div>
  );
};
export default Home;
