import React, { Component } from "react";
import {
  Container,
  Col,
  Row,
  Input,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from "reactstrap";
import "../home.css";
import Navbar from "../Navbar/Navbars";
import axios from "axios";
import _ from "lodash";
import Firebase from "firebase";
class Member extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberSheet: [],
      memberFB: [],
      loading1: false,
      search: "",
      modal: false,
      userID: "",
      idkey: "",
      userName: "",
      money: 0,
      message2: "",
    };
  }

  componentDidMount() {
    let app1 = Firebase.database().ref("dataMember");
    app1.on("value", (snapshot) => {
      this.changeKeyUser(snapshot.val());
      this.setState({
        loading1: true,
      });
    });
  }

  changeKeyUser = (values) => {
    let usersVal = values;
    let users = _(usersVal)
      .keys()
      .map((userKey) => {
        let cloned = _.clone(usersVal[userKey]);
        cloned.key = userKey;
        return cloned;
      })
      .value();
    this.setState({
      memberFB: users,
    });
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
    this.setState({ message2: "" });
  };

  updateSearch = (e) => {
    this.setState({
      search: e.target.value.substr(0, 20),
    });
  };

  send2 = async () => {
    await axios.post(`https://back-housekk1.herokuapp.com/register-notify/2`, {
      userID: this.state.userID, 
      userName: this.state.userName,
    });
  };

  sendMember = async () => {
    if (
      this.state.idkey !== "" &&
      this.state.userName !== "" &&
      this.state.userID !== "" &&
      this.state.money !== 0
    ) {
      let dataForm = {
        userID: this.state.userID,
        idkey: this.state.idkey,
        userName: this.state.userName,
        money: Number(this.state.money),
      };
      await axios
        .post("https://housekk1-75612.firebaseio.com/dataMember.json", dataForm)
        .then(() => {
          this.send2();
          this.setState({ message2: "สมัครสมาชิกเรียบร้อยแล้วค่ะ" });
          setTimeout(() => {
            this.setState({ modal: false });
            this.setState({ message2: "" });
          }, 3000);
        });
      this.setState({
        userID: "",
        idkey: "",
        userName: "",
        money: 0,
      });
    } else {
      this.setState({ message2: "กรุณากรอกข้อมูลให้ครบค่ะ" });
    }
  };

  render() {
    const { memberFB } = this.state;
    let filterData = memberFB.filter((item) => {
      return (
        item.userID.indexOf(this.state.search.toLowerCase()) !== -1 ||
        item.userName.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
          -1 ||
        item.idkey.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
      );
    });
    return (
      <div>
        <Navbar />
        <Container>
          <h1 className="textHead">สมาชิก</h1>
          <center>
            <Input
              className="sizeSearch"
              type="text"
              placeholder="ค้นหาสมาชิก"
              value={this.state.search}
              onChange={this.updateSearch}
            ></Input>
          </center>
          {this.state.loading1 === true ? (
            <div>
              <Row>
                <Button
                  color="dark"
                  style={{
                    marginBottom: "20px",
                    border: "2px solid #e7e7e7 ",
                  }}
                  size="lg"
                  onClick={this.toggle}
                >
                  เพิ่มสมาชิก
                </Button>
              </Row>
              <Row>
                <Col>
                  <center>
                    <table
                      style={{
                        width: "680px",
                        marginTop: "30px",
                        marginBottom: "30px",
                      }}
                    >
                      <thead>
                        <tr className="theada">
                          <td style={{ padding: "10px" }}>UserID</td>
                          <td style={{ padding: "10px" }}>Username</td>
                          <td style={{ padding: "10px" }}>เงินคงเหลือ</td>
                        </tr>
                      </thead>
                      {filterData.map((text, idx) => {
                        var nf = new Intl.NumberFormat();
                        return (
                          <tbody key={idx}>
                            <tr className="tra">
                              <td style={{ padding: "5px" }}>{text.userID}</td>
                              <td style={{ padding: "5px" }}>
                                {text.userName}
                              </td>
                              <td style={{ padding: "5px" }}>
                                {nf.format(text.money)}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  </center>
                </Col>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                  <ModalHeader toggle={this.toggle}>
                    <p
                      style={{
                        margin: "10px",
                        fontWeight: "bolder",
                      }}
                    >
                      เพิ่มสมาชิกใหม่
                    </p>
                  </ModalHeader>
                  <ModalBody>
                    <FormGroup row>
                      <Label style={{ marginLeft: "15px" }}>UserID</Label>
                      <Input
                        type="text"
                        style={{ marginLeft: "5%", marginRight: "5%" }}
                        placeholder="กรอกเลขลำดับสำหรับใช้ในการแทง เช่น 1"
                        required
                        onChange={(e) =>
                          this.setState({
                            userID: e.target.value,
                          })
                        }
                      ></Input>
                      <Label style={{ marginTop: "20px", marginLeft: "15px" }}>
                        IDkey
                      </Label>
                      <Input
                        type="text"
                        style={{ marginLeft: "5%", marginRight: "5%" }}
                        placeholder="กรอก IDkey"
                        required
                        onChange={(e) =>
                          this.setState({
                            idkey: e.target.value,
                          })
                        }
                      ></Input>
                      <Label style={{ marginTop: "20px", marginLeft: "15px" }}>
                        Username
                      </Label>
                      <Input
                        type="text"
                        style={{ marginLeft: "5%", marginRight: "5%" }}
                        placeholder="กรอก Username"
                        required
                        onChange={(e) =>
                          this.setState({
                            userName: e.target.value,
                          })
                        }
                      ></Input>
                      <Label style={{ marginTop: "20px", marginLeft: "15px" }}>
                        ยอดเงินฝาก
                      </Label>
                      <Input
                        type="number"
                        style={{ marginLeft: "5%", marginRight: "5%" }}
                        placeholder="กรอกยอดเงินฝาก"
                        required
                        onChange={(e) =>
                          this.setState({
                            money: e.target.value,
                          })
                        }
                      ></Input>
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <center>
                      {this.state.message2 === "สมัครสมาชิกเรียบร้อยแล้วค่ะ" ? (
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "green",
                          }}
                        >
                          {this.state.message2}
                        </p>
                      ) : (
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {this.state.message2}
                        </p>
                      )}
                    </center>
                    <div style={{ marginTop: "80px" }}>
                      <Button
                        style={{ marginRight: "20px" }}
                        color="danger"
                        onClick={this.toggle}
                      >
                        ยกเลิก
                      </Button>
                      <Button color="primary" onClick={this.sendMember}>
                        ยืนยัน
                      </Button>
                    </div>
                  </ModalFooter>
                </Modal>
              </Row>
            </div>
          ) : (
            <div style={{ marginTop: "100px" }}>
              <center>
                <div style={{ marginBottom: "80px" }}>
                  <Spinner
                    className="spin"
                    size="md"
                    type="grow"
                    color="primary"
                  />
                  <Spinner
                    className="spin"
                    size="md"
                    type="grow"
                    color="secondary"
                  />
                  <Spinner
                    className="spin"
                    size="md"
                    type="grow"
                    color="success"
                  />
                  <Spinner
                    className="spin"
                    size="md"
                    type="grow"
                    color="danger"
                  />
                  <Spinner
                    className="spin"
                    size="md"
                    type="grow"
                    color="warning"
                  />
                </div>
              </center>
            </div>
          )}
        </Container>
      </div>
    );
  }
}

export default Member;
