import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  Progress,
  Badge,
} from "reactstrap";
import axios from "axios";
import _ from "lodash";
import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBYVkn2hjc6TBWkKHZ_xE-6Om9P6PhoaW8",
  authDomain: "housekk1-75612.firebaseapp.com",
  databaseURL: "https://housekk1-75612.firebaseio.com",
  projectId: "housekk1-75612",
  storageBucket: "housekk1-75612.appspot.com",
  messagingSenderId: "1077332457029",
  appId: "1:1077332457029:web:d0dee30a92fb94fdd5952c"
};

firebase.initializeApp(firebaseConfig);

const InputRef = (props) => {
  const [newData, setNewData] = useState({
    round: "",
    timeOpen: "1/1/2020, 00:00:00",
    timeClose: "1/1/2020, 00:00:00",
    result2: 0,
    result3_TG: ""
  });
  const [dataBean, setDataBean] = useState({});
  const [files1, setFiles1] = useState(null);
  const [files2, setFiles2] = useState(null);
  const [files3, setFiles3] = useState(null);
  const [percen1, setPercen1] = useState(0);
  const [percen2, setPercen2] = useState(0);
  const [percen3, setPercen3] = useState(0);
  const [uploadValue1, setUploadValue1] = useState(false);
  const [uploadValue2, setUploadValue2] = useState(false);
  const [uploadValue3, setUploadValue3] = useState(false);
  const [urlPic1, setUrlPic1] = useState(null);
  const [urlPic2, setUrlPic2] = useState(null);
  const [urlPic3, setUrlPic3] = useState(null);
  const [step1, setStep1] = useState(0);
  const [step2, setStep2] = useState(0);
  const [step3, setStep3] = useState(0);
  const [checkRe, setCheckRe] = useState(false);
  const [calCheck, setCalCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [round, setRound] = useState("");
  const [members, setMembers] = useState("");
  const [dataMerge, setDataMerge] = useState("");

  const [Tiger_color, setTiger_color] = useState('secondary')
  const [Dragon_color, setDragon_color] = useState('secondary')
  const [Doublet1_color, setDoublet1_color] = useState('secondary')
  const [Doublet2_color, setDoublet2_color] = useState('secondary')
  const [Tigame_color, setTigame_color] = useState('secondary')

  useEffect(() => {
    (async () => {
      await dataUser();
      await toMerge();
      let dataList = await dataForm();
      if (dataList != null || dataList != undefined) {
        setDataBean({ dataList });
        console.log('Calculate : ', dataList);
      }
    })();
  }, [newData.result2, newData.timeOpen, newData.timeClose]);

  const dataUser = () => {
    let app1 = firebase.database().ref("dataTigerdargon/userInput");
    app1.on("value", (snapshot) => {
      changeKeyUser(snapshot.val());
    });

    let app2 = firebase.database().ref("dataTigerdargon/stat");
    app2.on("value", (snapshot) => {
      changeKeyRound(snapshot.val());
    });

    let app3 = firebase.database().ref("dataMember");
    app3.on("value", (snapshot) => {
      changeKeyMoney(snapshot.val());
    });
    console.log('uerinput: ', userInput);
    return Promise.resolve()
  };

  const changeKeyUser = (values) => {
    let usersVal = values;
    let users = _(usersVal)
      .keys()
      .map((userKey) => {
        let cloned = _.clone(usersVal[userKey]);
        cloned.key = userKey;
        return cloned;
      })
      .value();
    setUserInput(users);
  };

  const changeKeyRound = (values) => {
    let usersVal = values;
    let users = _(usersVal)
      .keys()
      .map((userKey) => {
        let cloned = _.clone(usersVal[userKey]);
        cloned.key = userKey;
        return cloned;
      })
      .value();
    setRound(users);
  };

  const changeKeyMoney = (values) => {
    let usersVal = values;
    let users = _(usersVal)
      .keys()
      .map((userKey) => {
        let cloned = _.clone(usersVal[userKey]);
        cloned.key = userKey;
        return cloned;
      })
      .value();
    setMembers(users);
  };

  const toMerge = () => {
    let dataMerge = [];
    //console.log(userInput);
    if (userInput !== "") {
      userInput.filter((item1) => {
        members.filter((item2) => {
          if (item2.idkey == item1.idkey) {
            dataMerge.push({
              ...item1,
              userName: item2.userName,
              refMoney: item2.money,
            });
          }
        });
      });
    }
    setDataMerge(dataMerge);
    console.log('dataMerge', dataMerge);
    return Promise.resolve()
  };

  const dataForm = () => {
    if (dataMerge !== "") {
      let dataList = dataMerge.map((item) => {
        // console.log(item);
        // const dataSplit = item.date.split(" ");
        // const dataSplit3 = dataSplit[1].split(":");
        // const dataSplit4 = dataSplit3[0] + dataSplit3[1] + dataSplit3[2];
        // const dataSplit5 = dataSplit4;
        // const num1 = Number(dataSplit5);
        // const dataSplit7 = newData.timeOpen.split(" ");
        // const dataSplit10 = dataSplit7[1].split(":");
        // const dataSplit11 = dataSplit10[0] + dataSplit10[1] + dataSplit10[2];
        // const dataSplit12 = dataSplit11;
        // const num2 = Number(dataSplit12);
        // const dataSplit14 = newData.timeClose.split(" ");
        // const dataSplit17 = dataSplit14[1].split(":");
        // const dataSplit18 = dataSplit17[0] + dataSplit17[1] + dataSplit17[2];
        // const dataSplit19 = dataSplit18;
        // const num3 = Number(dataSplit19);

        if (item.result2 != '' || item.result2 != undefined)
        // && dataSplit[0] == dataSplit7[0] &&
        // dataSplit[0] == dataSplit14[0] &&
        // num1 >= num2 &&
        // num1 <= num3
        {
          if (Number(item.result2) == 1) {
            if (Number(item.result2) == newData.result2) {
              let datas = {
                userName: item.userName,
                idkey: item.idkey,
                result2_1: item.money,
                loseMoney: 0,
                winMoney: Number(item.money) + (Number(item.money) * 1),
                refMoney: item.refMoney,
                round: newData.round,
              };
              return datas;
            }
            else {
              if (newData.result2 == 3 && newData.result3_TG == 'tiger') {
                let datas = {
                  userName: item.userName,
                  idkey: item.idkey,
                  result2_1: item.money,
                  loseMoney: 0,
                  winMoney: Number(item.money) + (Number(item.money) * 1),
                  refMoney: item.refMoney,
                  round: newData.round,

                };
                return datas;
              }
              else {
                let datas = {
                  userName: item.userName,
                  idkey: item.idkey,
                  result2_1: item.money,
                  loseMoney: 0,
                  winMoney: 0,
                  refMoney: item.refMoney,
                  round: newData.round,

                };
                return datas;
              }
            }
          }
          if (Number(item.result2) == 2) {
            if (Number(item.result2) == newData.result2) {
              let datas = {
                userName: item.userName,
                idkey: item.idkey,
                result2_2: item.money,
                loseMoney: 0,
                winMoney: Number(item.money) + (Number(item.money) * 1),
                refMoney: item.refMoney,
                round: newData.round,
              };
              return datas;
            }
            else {
              if (newData.result2 == 3 && newData.result3_TG == 'dragon') {
                let datas = {
                  userName: item.userName,
                  idkey: item.idkey,
                  result2_2: item.money,
                  loseMoney: 0,
                  winMoney: Number(item.money) + (Number(item.money) * 1),
                  refMoney: item.refMoney,
                  round: newData.round,

                };
                return datas;
              }
              else {
                let datas = {
                  userName: item.userName,
                  idkey: item.idkey,
                  result2_2: item.money,
                  loseMoney: 0,
                  winMoney: 0,
                  refMoney: item.refMoney,
                  round: newData.round,

                };
                return datas;
              }
            }

          }
          if (Number(item.result2) == 3) {
            if (Number(item.result2) == newData.result2) {
              let datas = {
                userName: item.userName,
                idkey: item.idkey,
                result2_3: item.money,
                result3_TG: newData.result3_TG,
                loseMoney: 0,
                winMoney: Number(item.money) + (Number(item.money) * 11),
                refMoney: item.refMoney,
                round: newData.round,
              };
              return datas;
            } else {
              if (newData.result2 == 4) {
                let datas = {
                  userName: item.userName,
                  idkey: item.idkey,
                  result2_3: item.money,
                  result3_TG: newData.result3_TG,
                  loseMoney: 0,
                  winMoney: Number(item.money) + (Number(item.money) * 11),
                  refMoney: item.refMoney,
                  round: newData.round,
                };
                return datas;
              }
              else {
                let datas = {
                  userName: item.userName,
                  idkey: item.idkey,
                  result2_3: item.money,
                  result3_TG: newData.result3_TG,
                  loseMoney: 0,
                  winMoney: 0,
                  refMoney: item.refMoney,
                  round: newData.round,
                };
                return datas;
              }
            }
          }
          if (Number(item.result2) == 4) {
            if (Number(item.result2) == newData.result2) {
              let datas = {
                userName: item.userName,
                idkey: item.idkey,
                result2_4: item.money,
                loseMoney: 0,
                winMoney: Number(item.money) + (Number(item.money) * 30),
                refMoney: item.refMoney,
                round: newData.round,
              };
              return datas;
            } else {
              let datas = {
                userName: item.userName,
                idkey: item.idkey,
                result2_4: item.money,
                loseMoney: 0,
                winMoney: 0,
                refMoney: item.refMoney,
                round: newData.round,
              };
              return datas;
            }
          }
        }

      });
      // setDataBean({
      //   dataList,
      // });
      // console.log('datalist ผลคำนวณ :', dataBean.dataList);
      return Promise.resolve(dataList)
    }


  };


  const sendFirebase = async () => {
    const dataResult = {
      round: newData.round,
      result: newData.result2,
      result3: newData.result3_TG,
      timeOpen: newData.timeOpen,
      timeClose: newData.timeClose,
    };
    await axios.post(
      "https://housekk1-75612.firebaseio.com/dataTigerdargon/stat.json",
      dataResult
    );
  };

  const sentData = async () => {
    let xmes = ''
    if (newData.result2 == 1) {
      xmes = 'ออก เสือ'
    }
    else if (newData.result2 == 2) {
      xmes = 'ออก มังกร'
    }
    else if (newData.result2 == 3) {
      if (newData.result3_TG === 'tiger') {
        xmes = 'ออก คู่เสือ'
      }
      if (newData.result3_TG === 'dragon') {
        xmes = 'ออก คู่มังกร'
      }
    }
    else if (newData.result2 == 4) {
      xmes = 'ออก ไทเกมส์'
    }

    await axios.post(`https://back-housekk1.herokuapp.com/tiger-notify/2`, {
      round: newData.round,
      result1: xmes
    });
    sendFirebase();
  };

  const getBean1 = () => {
    axios.post(`https://back-housekk1.herokuapp.com/Tiger/1`);
  };
  const getBean2 = () => {
    axios.post(`https://back-housekk1.herokuapp.com/Tiger/2`);
  };
  const getBean3 = () => {
    axios.post(`https://back-housekk1.herokuapp.com/Tiger/3`);
  };
  const getBean4 = () => {
    axios.post(`https://back-housekk1.herokuapp.com/Tiger/4`, {
      round: newData.round,
    });
  };
  const getBean5 = () => {
    axios.post(`https://back-housekk1.herokuapp.com/Tiger/5`, {
      round: newData.round,
    });
  };
  const getBean6 = () => {
    axios.post(`https://back-housekk1.herokuapp.com/Tiger/6`);
  };
  const getBean7 = () => {
    axios.post(`https://back-housekk1.herokuapp.com/Tiger/7`);
  };

  const appendLeadingZeroes = (n) => {
    if (n <= 9) {
      return "0" + n;
    }
    return n;
  };

  const openRound = () => {
    getBean5();
    const months = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    var date = new Date();
    var datestring =
      date.getFullYear() +
      "-" +
      months[date.getMonth()] +
      "-" +
      appendLeadingZeroes(date.getDate()) +
      " " +
      appendLeadingZeroes(date.getHours()) +
      ":" +
      appendLeadingZeroes(date.getMinutes()) +
      ":" +
      appendLeadingZeroes(date.getSeconds());
    setNewData({ ...newData, timeOpen: datestring });
  };

  const closeRound = () => {
    getBean4();
    const months = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    var date = new Date();
    var datestring =
      date.getFullYear() +
      "-" +
      months[date.getMonth()] +
      "-" +
      appendLeadingZeroes(date.getDate()) +
      " " +
      appendLeadingZeroes(date.getHours()) +
      ":" +
      appendLeadingZeroes(date.getMinutes()) +
      ":" +
      appendLeadingZeroes(date.getSeconds());
    setNewData({ ...newData, timeClose: datestring });
  };

  const sentImagenotify = async () => {
    await axios.post(`https://back-housekk1.herokuapp.com/tiger-notify/3`, {
      round: newData.round,
      message1: "สถิติผลการออก",
      image1: urlPic1,
      message2: "ผลได้เสียประจำรอบที่",
      image2: urlPic2,
      message3: "ยอดเงินสุทธิ",
      image3: urlPic3,
    });
    setNewData({
      round: 0,
      timeOpen: "1/1/2020, 00:00:00",
      timeClose: "1/1/2020, 00:00:00",
      result2: 0,
      result3_TG: ""
    });
    window.location.reload();
  };

  const onUpload1 = () => {
    if (
      files1.type === "image/jpeg" ||
      (files1.type === "image/png" && files1 !== null)
    ) {
      const storageRef = firebase.storage().ref("/images/" + files1.name);
      const task = storageRef.put(files1);
      task.on(
        "state_changed",
        function progress(snapshot) {
          let percen =
            parseInt(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPercen1(percen);
          setStep1(1);
        },
        function error(error) {
          console.log(error.message);
        },
        function complete() {
          storageRef.getDownloadURL().then(async (url) => {
            await setUrlPic1(url);
            setUploadValue1(true);
          });
        }
      );
    } else {
      alert("เลือกไฟล์ .jpg หรือ .png เท่านั้นค่ะ");
    }
  };

  const onUpload2 = () => {
    if (
      files2.type === "image/jpeg" ||
      (files2.type === "image/png" && files2 !== null)
    ) {
      const storageRef = firebase.storage().ref("/images/" + files2.name);
      const task = storageRef.put(files2);
      task.on(
        "state_changed",
        function progress(snapshot) {
          let percen =
            parseInt(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPercen2(percen);
          setStep2(1);
        },
        function error(error) {
          console.log(error.message);
        },
        function complete() {
          storageRef.getDownloadURL().then(async (url) => {
            await setUrlPic2(url);
            setUploadValue2(true);
          });
        }
      );
    } else {
      alert("เลือกไฟล์ .jpg หรือ .png เท่านั้นค่ะ");
    }
  };

  const onUpload3 = () => {
    if (
      files3.type === "image/jpeg" ||
      (files3.type === "image/png" && files3 !== null)
    ) {
      const storageRef = firebase.storage().ref("/images/" + files3.name);
      const task = storageRef.put(files3);
      task.on(
        "state_changed",
        function progress(snapshot) {
          let percen =
            parseInt(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPercen3(percen);
          setStep3(1);
        },
        function error(error) {
          console.log(error.message);
        },
        function complete() {
          storageRef.getDownloadURL().then(async (url) => {
            await setUrlPic3(url);
            setUploadValue3(true);
          });
        }
      );
    } else {
      alert("เลือกไฟล์ .jpg หรือ .png เท่านั้นค่ะ");
    }
  };

  return (
    <Container id="my-node" style={{ padding: "30px" }}>
      <div>
        <Row className="mr-betaween">
          <Button className="bts-bts" color="success" onClick={openRound}>
            เปิดรอบแทง
          </Button>
          <Button className="bts-bts" color="dark" onClick={getBean1}>
            นับครั้งที่ 1
          </Button>
          <Button className="bts-bts" color="info" onClick={getBean2}>
            นับครั้งที่ 2
          </Button>
          <Button className="bts-bts" color="warning" onClick={getBean3}>
            นับครั้งที่ 3
          </Button>
          <Button className="bts-bts" color="danger" onClick={closeRound}>
            ปิดรอบแทง
          </Button>
        </Row>
        <center>
          <Button
            style={{ marginBottom: "20px" }}
            className="bts-bts"
            color="primary"
            onClick={getBean7}
          >
            กติกา
          </Button>
          <Button
            style={{ marginBottom: "20px" }}
            className="bts-bts"
            color="primary"
            onClick={getBean6}
          >
            ตัวอย่าง
          </Button>
        </center>
      </div>
      <h2 className="textSub">บันทึกผลที่ออก</h2>
      <Col className="textSub">
        <FormGroup row>
          <Label style={{ fontSize: "25px" }}>รอบที่</Label>
          <Col>
            <Input
              name="round"
              id="roundCount"
              value={newData.round}
              placeholder="กรอกเลขรอบ"
              onChange={(e) =>
                setNewData({ ...newData, round: e.target.value })
              }
            />
          </Col>
        </FormGroup>
      </Col>

      <Col className="textSub">
        <FormGroup row>
          <Label style={{ marginRight: "25px", fontSize: "25px" }}>ผลที่ออก</Label>

          <Row>
            <Button
              style={{ margin: '5px' }}
              color={Tiger_color}
              onClick={
                async () => {
                  const set1 = () => {
                    let x = 'success'
                    setNewData({ ...newData, result2: 1 })
                    return Promise.resolve(x)
                  }

                  if (newData.result2 == 0) {
                    await set1()
                    console.log('ออกเสือ', newData.result2)
                    setCheckRe(true)
                    setTiger_color('success');

                  }
                  else if (newData.result2 == 1) {
                    setNewData({ ...newData, result2: 0 })
                    setTiger_color('secondary');
                    console.log('ไม่ออกเสือ', newData.result2)
                    setCheckRe(false)
                  }
                }}>
              เสือ
            </Button>
            <Button
              style={{ margin: '5px' }}
              color={Dragon_color}
              onClick={
                async () => {
                  const set1 = () => {
                    let x = 'success'
                    setNewData({ ...newData, result2: 2 })
                    return Promise.resolve(x)
                  }
                  if (newData.result2 == 0) {
                    await set1()
                    console.log('ออกมังกร', newData.result2)
                    setCheckRe(true)
                    setDragon_color('success');

                  }
                  else if (newData.result2 == 2) {
                    setNewData({ ...newData, result2: 0 })
                    setCheckRe(false)
                    setDragon_color('secondary');
                    console.log('ไม่ออกมังกร', newData.result2)
                  }
                }}>
              มังกร
            </Button>
            <Button
              style={{ margin: '5px' }}
              color={Doublet1_color}
              onClick={
                async () => {
                  const set1 = () => {
                    let x = 'success'
                    setNewData({ ...newData, result2: 3, result3_TG: 'tiger' })
                    return Promise.resolve(x)
                  }
                  if (newData.result2 == 0) {
                    await set1()
                    console.log('ออกคู่', newData.result2, newData.result3_TG)
                    setCheckRe(true)
                    setDoublet1_color('success');

                  }
                  else if (newData.result2 == 3) {
                    setNewData({ ...newData, result2: 0, result3_TG: '' })
                    setCheckRe(false)
                    setDoublet1_color('secondary');
                    console.log('ไม่ออกคู่', newData.result2, newData.result3_TG)
                  }
                }}>
              คู่ เสือ
            </Button>
            <Button
              style={{ margin: '5px' }}
              color={Doublet2_color}
              onClick={
                async () => {
                  const set1 = () => {
                    let x = 'success'
                    setNewData({ ...newData, result2: 3, result3_TG: 'dragon' })
                    return Promise.resolve(x)
                  }
                  if (newData.result2 == 0) {
                    await set1()
                    console.log('ออกคู่', newData.result2, newData.result3_TG)
                    setCheckRe(true)
                    setDoublet2_color('success');

                  }
                  else if (newData.result2 == 3) {
                    setNewData({ ...newData, result2: 0, result3_TG: '' })
                    setCheckRe(false)
                    setDoublet2_color('secondary');
                    console.log('ไม่ออกคู่', newData.result2, newData.result3_TG)
                  }
                }}>
              คู่ มังกร
            </Button>
            <Button
              style={{ margin: '5px' }}
              color={Tigame_color}
              onClick={
                async () => {
                  const set1 = () => {
                    let x = 'success'
                    setNewData({ ...newData, result2: 4 })
                    return Promise.resolve(x)
                  }
                  if (newData.result2 == 0) {
                    await set1()
                    console.log('ออกไทเกมส์', newData.result2)
                    setCheckRe(true)
                    setTigame_color('success');

                  }
                  else if (newData.result2 == 4) {
                    setNewData({ ...newData, result2: 0 })
                    setCheckRe(false)
                    setTigame_color('secondary');
                    console.log('ไม่ออกไทเกมส์', newData.result2)
                  }
                }}>
              ไทเกมส์
            </Button>
          </Row>
        </FormGroup>
      </Col>

      <Card>
        <span
          style={{
            marginTop: "10px",
            fontSize: "20px",
            fontWeight: "bolder",
            color: "red",
            textAlign: "center",
          }}
        >
          !!! ตรวจสอบให้ถูกต้องก่อนกดบันทึก !!!
        </span>
        <CardBody>
          <span style={{ marginRight: "10px" }}>รอบที่ : {newData.round}</span>{" "}
          <br />
          <span style={{ marginRight: "10px" }}>
            เวลาเปิดรอบ : {newData.timeOpen}
          </span>{" "}
          <br />
          <span style={{ marginRight: "10px" }}>
            เวลาปิดรอบ : {newData.timeClose}
          </span>{" "}
          <br />

          {newData.result2 > 0 && newData.result2 === 3 ? (
            <a>
              ผลที่ออก : คู่
              {newData.result3_TG === 'tiger' && <a>
                เสือ
              </a>}
              {newData.result3_TG === 'dragon' && <a>
                มังกร
              </a>}
            </a>
          ) : (
              <a>
                ผลที่ออก :
                {newData.result2 === 1 && <a>
                  เสือ
              </a>}
                {newData.result2 === 2 && <a>
                  มังกร
              </a>}
                {newData.result2 === 4 && <a>
                  ไทเกมส์
              </a>}
              </a>
            )}




        </CardBody>
      </Card>
      <FormGroup style={{ fontSize: "15px", color: "white" }}></FormGroup>

      {
        calCheck == true ? (
          <Button
            style={{
              marginTop: "8px",
              marginBottom: "25px",
              border: "2px solid #e7e7e7 ",
            }}
            color="primary"
            size="lg"
            onClick={sentData}
          >
            บันทึกผล
          </Button>
        ) : (<Button
          style={{
            marginTop: "8px",
            marginBottom: "25px",
            border: "2px solid #e7e7e7 ",
          }}
          color="primary"
          size="lg"
          disabled
        >
          โปรดกดคำนวณก่อน
        </Button>)
      }


      {
        checkRe == true ? (
          <Button
            className="bt2"
            style={{
              marginLeft: "20px",
              marginBottom: "15px",
              border: "2px solid #e7e7e7 ",
            }}
            size="lg"
            onClick={async () => {
              const beans = dataBean.dataList;

              const dataFilters = beans.filter(function (element) {
                if (element !== undefined) {
                  return beans;
                }
              });

              const groupBy = (arr, key) => {
                return arr.reduce((accumulator, currentValue) => {
                  if (accumulator[currentValue[key]]) {
                    accumulator[currentValue[key]].push(currentValue);
                  } else {
                    accumulator = {
                      ...accumulator,
                      [currentValue[key]]: [currentValue],
                    };
                  }
                  return accumulator;
                }, {});
              };

              let arr = [];
              let result = [];
              for (let [key, value] of Object.entries(
                groupBy(dataFilters, "userName")
              )) {
                arr = [
                  ...arr,
                  value.reduce((accumulator, currentValue) => {
                    if (currentValue["winMoney"]) {
                      if (accumulator["winMoney"]) {
                        accumulator["winMoney"] += currentValue["winMoney"];
                      } else {
                        accumulator["winMoney"] = currentValue["winMoney"];
                      }
                    } else if (currentValue["loseMoney"]) {
                      if (accumulator["loseMoney"]) {
                        accumulator["loseMoney"] += currentValue["loseMoney"];
                      } else {
                        accumulator["loseMoney"] = currentValue["loseMoney"];
                      }
                    }
                    return accumulator;
                  }),
                ];
                result = [
                  ...result,
                  value.reduce((accumulator, currentValue) => {
                    return {
                      ...accumulator,
                      ...currentValue,
                    };
                  }),
                ];
              }

              let datasBean = [];
              arr.filter((item) => {
                result.filter((item2) => {
                  if (item.idkey === item2.idkey) {
                    datasBean.push({
                      ...item,
                      totalMoney: item.refMoney + item.winMoney + item.loseMoney,
                      result2_1: item2.result2_1,
                      result2_2: item2.result2_2,
                      result2_3: item2.result2_3,
                      result2_4: item2.result2_4,
                      answer: newData.result2,
                      result3: newData.result3_TG
                    });
                  }
                });
              });

              for (const key in datasBean) {
                await axios.post(
                  "https://housekk1-75612.firebaseio.com/dataTigerdargon/concluResult.json",
                  datasBean[key]
                );
              }
              setCalCheck(true)
            }}
            color="danger"
          >
            คำนวณ
          </Button>
        ) : (
            <Button
              className="bt2"
              style={{
                marginLeft: "20px",
                marginBottom: "15px",
                border: "2px solid #e7e7e7 ",
              }}
              size="lg"
              color="warning"
            >
              Wait
            </Button>
          )
      }
      <br />
      <Label className="textSub">Upload file</Label>
      <Row>
        <Label
          className="textSub"
          style={{ fontSize: "18px", marginRight: "61px", marginLeft: "15px" }}
        >
          สถิติผลการออก
        </Label>
        {uploadValue1 === true ? (
          <Col>
            <h1
              style={{
                fontSize: "18px",
                marginLeft: "15px",
                fontWeight: "bolder",
              }}
              className="textSub"
            >
              <Badge
                style={{ padding: "10px", width: "100px" }}
                color="success"
                pill
              >
                Success !
              </Badge>
            </h1>
          </Col>
        ) : (
            <div>
              {step1 === 0 ? (
                <Col style={{ color: "white" }}>
                  <input
                    style={{ paddingLeft: "15px" }}
                    className="upload"
                    type="file"
                    onChange={(e) => {
                      setFiles1(e.target.files[0]);
                    }}
                  />
                  {files1 !== null ? (
                    <Button
                      color="warning"
                      style={{
                        width: "100px",
                        border: "2px solid #e7e7e7 ",
                      }}
                      onClick={onUpload1}
                    >
                      URL
                    </Button>
                  ) : (
                      <Button
                        color="warning"
                        style={{
                          width: "100px",
                          border: "2px solid #e7e7e7 ",
                        }}
                        onClick={(e) => {
                          alert("กรุณาเลือกไฟล์ก่อนค่ะ");
                        }}
                      >
                        URL
                      </Button>
                    )}
                </Col>
              ) : (
                  <Col style={{ color: "white" }}>
                    <div>{percen1}%</div>
                    <Progress
                      style={{ width: "400px" }}
                      striped
                      color="danger"
                      value={percen1}
                    />
                  </Col>
                )}
            </div>
          )}
      </Row>
      <Row>
        <Label
          className="textSub"
          style={{ fontSize: "18px", marginRight: "30px", marginLeft: "15px" }}
        >
          ผลได้เสียประจำรอบ
        </Label>
        {uploadValue2 === true ? (
          <Col>
            <h1
              style={{
                fontSize: "18px",
                marginLeft: "15px",
                fontWeight: "bolder",
              }}
              className="textSub"
            >
              <Badge
                style={{ padding: "10px", width: "100px" }}
                color="success"
                pill
              >
                Success !
              </Badge>
            </h1>
          </Col>
        ) : (
            <div>
              {step2 === 0 ? (
                <Col style={{ color: "white" }}>
                  <input
                    style={{ paddingLeft: "15px" }}
                    className="upload"
                    type="file"
                    onChange={(e) => {
                      setFiles2(e.target.files[0]);
                    }}
                  />
                  {files2 !== null ? (
                    <Button
                      color="warning"
                      style={{
                        width: "100px",
                        border: "2px solid #e7e7e7 ",
                      }}
                      onClick={onUpload2}
                    >
                      URL
                    </Button>
                  ) : (
                      <Button
                        color="warning"
                        style={{
                          width: "100px",
                          border: "2px solid #e7e7e7 ",
                        }}
                        onClick={(e) => {
                          alert("กรุณาเลือกไฟล์ก่อนค่ะ");
                        }}
                      >
                        URL
                      </Button>
                    )}
                </Col>
              ) : (
                  <Col style={{ color: "white" }}>
                    <div>{percen2}%</div>
                    <Progress
                      style={{ width: "400px" }}
                      striped
                      color="danger"
                      value={percen2}
                    />
                  </Col>
                )}
            </div>
          )}
      </Row>
      <Row>
        <Label
          className="textSub"
          style={{ fontSize: "18px", marginRight: "88px", marginLeft: "15px" }}
        >
          ยอดเงินสุทธิ
        </Label>
        {uploadValue3 === true ? (
          <Col>
            <h1
              style={{
                fontSize: "18px",
                marginLeft: "15px",
                fontWeight: "bolder",
              }}
              className="textSub"
            >
              <Badge
                style={{ padding: "10px", width: "100px" }}
                color="success"
                pill
              >
                Success !
              </Badge>
            </h1>
          </Col>
        ) : (
            <div>
              {step3 === 0 ? (
                <Col style={{ color: "white" }}>
                  <input
                    style={{ paddingLeft: "15px" }}
                    className="upload"
                    type="file"
                    onChange={(e) => {
                      setFiles3(e.target.files[0]);
                    }}
                  />
                  {files3 !== null ? (
                    <Button
                      color="warning"
                      style={{
                        width: "100px",
                        border: "2px solid #e7e7e7 ",
                      }}
                      onClick={onUpload3}
                    >
                      URL
                    </Button>
                  ) : (
                      <Button
                        color="warning"
                        style={{
                          width: "100px",
                          border: "2px solid #e7e7e7 ",
                        }}
                        onClick={(e) => {
                          alert("กรุณาเลือกไฟล์ก่อนค่ะ");
                        }}
                      >
                        URL
                      </Button>
                    )}
                </Col>
              ) : (
                  <Col style={{ color: "white" }}>
                    <div>{percen3}%</div>
                    <Progress
                      style={{ width: "400px" }}
                      striped
                      color="danger"
                      value={percen3}
                    />
                  </Col>
                )}
            </div>
          )}
      </Row>
      <Button
        color="dark"
        style={{
          marginTop: "15px",
          marginBottom: "15px",
          border: "2px solid #e7e7e7 ",
        }}
        size="lg"
        onClick={sentImagenotify}
      >
        Upload
      </Button>
    </Container >
  );
};
export default InputRef;
