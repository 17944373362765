import React, { Component } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Home from "./components/Home/Home";
// import Hilopage from "./components/Hilo/Hilopage";
import Tigerpage from "./components/Tiger/Tigerpage";
import "bootstrap/dist/css/bootstrap.min.css";
import Money from "./components/Money/Money";
import TableTiger from "./components/Tiger/TableTiger/TableTiger";
import Member from "./components/Member/Member";
import Login from './components/Login/Login'
class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/" component={Home} />
            <Route exact path="/tiger" component={Tigerpage} />
            <Route exact path="/money" component={Money} />
            <Route exact path="/tabletigeruser" component={TableTiger} />
            <Route exact path="/member" component={Member} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
