import React, { Component } from "react";
import {
  Container,
  Col,
  Row,
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Card,
} from "reactstrap";
import "../home.css";
import Navbar from "../Navbar/Navbars";
import _ from "lodash";
import Firebase from "firebase";
import axios from "axios";
class Money extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberFB: [],
      search: "",
      loading1: false,
      modal: false,
      money: 0,
      moneyX: 0,
      deposite: false,
      withdraw: false,
      notify_userID: "",
      notify_userName: "",
    };
  }

  componentDidMount() {
    let app1 = Firebase.database().ref("dataMember");
    app1.on("value", (snapshot) => {
      this.changeKeyUser(snapshot.val());
      this.setState({
        loading1: true,
      });
    });
  }

  changeKeyUser = (values) => {
    let usersVal = values;
    let users = _(usersVal)
      .keys()
      .map((userKey) => {
        let cloned = _.clone(usersVal[userKey]);
        cloned.key = userKey;
        return cloned;
      })
      .value();
    this.setState({
      memberFB: users,
    });
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  updateSearch = (e) => {
    this.setState({
      search: e.target.value.substr(0, 20),
    });
  };

  updateDeposite = () => {
    this.setState({
      deposite: true,
    });
  };

  updateWithdraw = () => {
    this.setState({
      withdraw: true,
    });
  };

  updateNotifydata = (text) => {
    this.setState({
      notify_userID: text.userID,
      notify_userName: text.userName,
    });
  };

  render() {
    const { memberFB } = this.state;
    const filterData = memberFB.filter((item) => {
      return item.userID.indexOf(this.state.search.toLowerCase()) !== -1;
    });
    return (
      <div>
        <Navbar />
        <Container>
          <h1 className="textHead">การเงิน</h1>
          <center>
            <Input
              className="sizeSearch"
              type="text"
              placeholder="กรุณากรอกเลข userID ของผู้ใช้ค่ะ"
              value={this.state.search}
              onChange={this.updateSearch}
            ></Input>
          </center>
          {filterData.map((text, idx) => {
            if (text.userID === this.state.search) {
              return (
                <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <Card style={{ marginTop: '20px', backgroundColor: "rgba(255, 255, 255, 0.75)", width: '450px' }}>
                    <FormGroup
                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
                      key={idx}>
                      <Label className="textSub1">ข้อมูลบัญชี</Label>
                      <FormGroup row>
                        <Label
                          className="textSub1"
                          style={{
                            fontSize: "18px",
                            marginRight: "30px",
                          }}
                        >
                          UserID
                    </Label>
                        <Input
                          style={{ marginLeft: '50px', width: "280px", cursor: "not-allowed" }}
                          type="text"
                          value={text.userID}
                          disabled={true}
                        ></Input>
                      </FormGroup>
                      <FormGroup row>
                        <Label
                          className="textSub1"
                          style={{
                            fontSize: "18px",
                            marginRight: "30px"
                          }}
                        >
                          Username
                    </Label>
                        <Input
                          style={{ marginLeft: '20px', width: "280px", cursor: "not-allowed" }}
                          type="text"
                          value={text.userName}
                          disabled={true}
                        ></Input>
                      </FormGroup>
                      <FormGroup row>
                        <Label
                          className="textSub1"
                          style={{
                            fontSize: "18px",
                            marginRight: "25px",
                          }}
                        >
                          ยอดเงินปัจจุบัน
                    </Label>
                        <Input
                          style={{ width: "280px", cursor: "not-allowed" }}
                          type="number"
                          value={text.money}
                          disabled={true}
                        ></Input>
                      </FormGroup>
                      <FormGroup row>
                        <Label
                          className="textSub1"
                          style={{ fontSize: "18px", marginRight: "62px" }}
                        >
                          จำนวนเงิน
                    </Label>
                        <Input
                          className="IpS"
                          style={{ width: "280px" }}
                          placeholder="จำนวนเงิน"
                          type="number"
                          name="money"
                          value={this.state.money}
                          onChange={(e) => {
                            this.setState({
                              money: e.target.value,
                              moneyX: e.target.value,
                            });
                          }}
                        ></Input>
                      </FormGroup>
                      <Row>
                        <Button
                          color="success"
                          style={{
                            marginBottom: "25px",
                            marginRight: "10px",
                            border: "2px solid #e7e7e7 ",
                          }}
                          size="lg"
                          onClick={() => {
                            this.updateDeposite();
                            this.toggle();
                          }}
                        >
                          ทำรายการฝากเงิน
                  </Button>
                        <Button
                          color="danger"
                          style={{
                            marginBottom: "25px",
                            border: "2px solid #e7e7e7 ",
                          }}
                          size="lg"
                          onClick={() => {
                            this.updateWithdraw();
                            this.toggle();
                          }}
                        >
                          ทำรายการถอนเงิน
                  </Button>
                      </Row>


                      <Modal isOpen={this.state.modal} toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle}>
                          ยืนยันการทำรายการ
                    </ModalHeader>
                        <ModalFooter>
                          <Button color="danger" onClick={this.toggle}>
                            ยกเลิก
                      </Button>
                          <Button
                            color="primary"
                            onClick={async () => {
                              let app = Firebase.database().ref(
                                `/dataMember/${text.key}`
                              );
                              let numNew = Number(this.state.money);
                              let numOld = Number(text.money);
                              let moneyX = Number(this.state.moneyX)
                              let total = numOld;
                              this.setState({
                                notify_userID: text.userID,
                                notify_userName: text.userName,
                              });

                              if (
                                this.state.deposite === true &&
                                this.state.withdraw === false
                              ) {
                                total = numNew + numOld;
                                await axios.post(
                                  `https://back-housekk1.herokuapp.com/register-notify/7`,
                                  {
                                    userID: text.userID,
                                    userName: text.userName,
                                    money: total,
                                    moneyX: moneyX
                                  }
                                );
                                this.setState({
                                  deposite: false,
                                  withdraw: false,
                                });
                              } else if (
                                this.state.deposite === false &&
                                this.state.withdraw === true
                              ) {
                                total = -1 * numNew + numOld;

                                await axios.post(
                                  `https://back-housekk1.herokuapp.com/register-notify/8`,
                                  {
                                    userID: text.userID,
                                    userName: text.userName,
                                    money: total,
                                    moneyX: moneyX
                                  }
                                );
                                this.setState({
                                  deposite: false,
                                  withdraw: false,
                                });
                              } else {
                                this.setState({
                                  deposite: false,
                                  withdraw: false,
                                });
                              }
                              app.update({
                                idkey: text.idkey,
                                money: total,
                                userID: text.userID,
                                userName: text.userName,
                              });
                              window.location.reload();
                            }}
                          >
                            ยืนยัน
                      </Button>
                        </ModalFooter>
                      </Modal>
                    </FormGroup>
                  </Card>
                </Container>
              );
            }
          })}
        </Container>
      </div>
    );
  }
}

export default Money;
