import React, { Component } from "react";
import Navbars from "../../Navbar/Navbars";
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import _ from "lodash";
import Firebase from "firebase";
import domtoimage from "dom-to-image";
import classnames from "classnames";
import "./table.css";

class TableTiger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInput: [],
      round: [],
      members: [],
      results: [],
      loading1: false,
      loading2: false,
      loading3: false,
      activeTab: "1",
      search: "",
      datestring: "",
      message: "",
      messageworning1: "",
      modal: false,
    };
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  componentDidMount() {
    let app1 = Firebase.database().ref("dataTigerdargon/userInput");
    app1.on("value", (snapshot) => {
      this.changeKeyUser(snapshot.val());
      this.setState({
        loading1: true,
      });
    });
    let app2 = Firebase.database().ref("dataTigerdargon/stat");
    app2.on("value", (snapshot) => {
      this.changeKeyRound(snapshot.val());
      this.setState({
        loading2: true,
      });
    });
    let app3 = Firebase.database().ref("dataMember");
    app3.on("value", (snapshot) => {
      this.changeKeyMoney(snapshot.val());
      this.setState({
        loading3: true,
      });
    });
    let app4 = Firebase.database().ref("dataTigerdargon/concluResult");
    app4.on("value", (snapshot) => {
      this.changeKeyconcluResult(snapshot.val());
      this.setState({
        loading3: true,
      });
    });
    this.dateSet();
  }

  changeKeyUser = (values) => {
    let usersVal = values;
    let users = _(usersVal)
      .keys()
      .map((userKey) => {
        let cloned = _.clone(usersVal[userKey]);
        cloned.key = userKey;
        return cloned;
      })
      .value();
    this.setState({
      userInput: users,
    });
  };

  changeKeyRound = (values) => {
    let usersVal = values;
    let users = _(usersVal)
      .keys()
      .map((userKey) => {
        let cloned = _.clone(usersVal[userKey]);
        cloned.key = userKey;
        return cloned;
      })
      .value();
    this.setState({ round: users });
  };

  changeKeyconcluResult = (values) => {
    let usersVal = values;
    let users = _(usersVal)
      .keys()
      .map((userKey) => {
        let cloned = _.clone(usersVal[userKey]);
        cloned.key = userKey;
        return cloned;
      })
      .value();
    this.setState({ results: users });
  };

  changeKeyMoney = (values) => {
    let usersVal = values;
    let users = _(usersVal)
      .keys()
      .map((userKey) => {
        let cloned = _.clone(usersVal[userKey]);
        cloned.key = userKey;
        return cloned;
      })
      .value();
    this.setState({ members: users });
  };

  appendLeadingZeroes = (n) => {
    if (n <= 9) {
      return "0" + n;
    }
    return n;
  };

  dateSet = () => {
    const months = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    var date = new Date();
    var year = date.getFullYear() + 543;
    var datestring =
      this.appendLeadingZeroes(date.getDate()) +
      "/" +
      months[date.getMonth()] +
      "/" +
      year;
    this.setState({ datestring });
  };

  toPng1 = () => {
    domtoimage
      .toJpeg(document.getElementById("สถิติผลการออก"), {
        quality: 1.0,
        width: 580,
      })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "สถิติผลการออก.jpeg";
        link.href = dataUrl;
        link.click();
      });
  };

  toPng2 = () => {
    domtoimage
      .toJpeg(document.getElementById("ผลได้เสียประจำรอบ"), {
        quality: 1.0,
        width: 960,
      })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "ผลได้เสียประจำรอบ.jpeg";
        link.href = dataUrl;
        link.click();
      });
  };

  toPng3 = () => {
    domtoimage
      .toJpeg(document.getElementById("ยอดเงินสุทธิ"), {
        quality: 1.0,
        width: 400,
      })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "ยอดเงินสุทธิ.jpeg";
        link.href = dataUrl;
        link.click();
      });
  };

  sortDescending = () => {
    const { userInput } = this.state;
    userInput.sort((a, b) => a - b).reverse();
    this.setState({ userInput });
  };

  updateSearch = (e) => {
    this.setState({
      search: e.target.value.substr(0, 20),
    });
  };

  sentmessagenotify = async () => {
    await axios
      .post(`https://back-housekk1.herokuapp.com/tiger-notify/1`, {
        message: this.state.message,
      })
      .then(() => {
        this.setState({ message: "" });
      });
  };

  sentwornningnotify1 = async () => {
    await axios
      .post(`https://back-housekk1.herokuapp.com/tiger-notify/5`, {
        message: this.state.messageworning1,
      })
      .then(() => {
        this.setState({ messageworning1: "" });
      });
  };

  toggle1 = () => {
    this.setState({ modal1: !this.state.modal1 });
  };
  toggle2 = () => {
    this.setState({ modal2: !this.state.modal2 });
  };

  render() {
    const { userInput, members } = this.state;
    let dataMerge = [];

    // userInput.filter((item1, i) => {
    //   if (item1.money != undefined) {
    //     members.filter((item2, j) => {
    //       // let moneyU = Number(item1.money);
    //       if (item2.idkey === item1.idkey) {
    //         dataMerge.push(item1);
    //       }
    //       // else if (
    //       //   item2.idkey === item1.idkey &&
    //       //   moneyU > item2.money &&
    //       //   item2.money > 0
    //       // ) {
    //       //   let notmes =
    //       //     "คุณ " +
    //       //     item2.userName +
    //       //     // " id = " +
    //       //     // item1.userID +
    //       //     " ยอดเงินไม่เพียงพอ แทง " +
    //       //     item1.money +
    //       //     " บาท โอกาสเสีย " +
    //       //     moneyU +
    //       //     " บาท แต่เหลือยอดเงิน " +
    //       //     item2.money +
    //       //     " บาท ทางระบบจะยกเลิกยอดนะคะ";

    //       //   let data = {
    //       //     date: item1.date,
    //       //     idkey: item1.idkey,
    //       //     key: item1.key,
    //       //     node2: notmes,
    //       //     userID: item1.userID,
    //       //   };

    //       //   let app = Firebase.database().ref(
    //       //     `dataTigerdargon/userInput/${item1.key}`
    //       //   );
    //       //   app.set({
    //       //     date: item1.date,
    //       //     idkey: item1.idkey,
    //       //     key: item1.key,
    //       //     node2: notmes,
    //       //     userID: item1.userID,
    //       //   });
    //       //   dataMerge.push(data);
    //       // }
    //     });
    //   }
    //   // else {
    //   //   dataMerge.push(item1);
    //   // }
    // });

    userInput.map((item1, i) => {
      dataMerge.push(item1);
    });

    console.log('dataMerge : ', dataMerge);

    const filterData = this.state.results.filter((item) => {
      return (
        item.round.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
      );
    });
    const filterRound = this.state.round.filter((item) => {
      if (this.state.search === item.round) {
        return item;
      }
    });


    let newSort = dataMerge.map((text, idx) => {
      if (text.result2 === 1) {
        return (
          <tbody key={idx}>
            <tr className="tra">
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}>{text.result2}</td>
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}>{text.money}</td>
              <td style={{ padding: "5px" }}>{text.userName}</td>
              <td style={{ padding: "5px" }}>{text.date}</td>
            </tr>
          </tbody>
        );
      }
      if (text.result2 === 2) {
        return (
          <tbody key={idx}>
            <tr className="tra">
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}>{text.result2}</td>
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}>{text.money}</td>
              <td style={{ padding: "5px" }}>{text.userName}</td>
              <td style={{ padding: "5px" }}>{text.date}</td>
            </tr>
          </tbody>
        );
      }
      if (text.result2 === 3) {
        return (
          <tbody key={idx}>
            <tr className="tra">
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}>{text.result2}</td>
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}>{text.money}</td>
              <td style={{ padding: "5px" }}>{text.userName}</td>
              <td style={{ padding: "5px" }}>{text.date}</td>
            </tr>
          </tbody>
        );
      }
      if (text.result2 === 4) {
        return (
          <tbody key={idx}>
            <tr className="tra">
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}>{text.result2}</td>
              <td style={{ padding: "5px" }}>{text.money}</td>
              <td style={{ padding: "5px" }}>{text.userName}</td>
              <td style={{ padding: "5px" }}>{text.date}</td>
            </tr>
          </tbody>
        );
      }
      // if (text.note) {
      //   return (
      //     <tbody key={idx}>
      //       <tr className="tra">
      //         <td style={{ padding: "5px" }}>{text.userID}</td>
      //         <td style={{ padding: "5px" }}></td>
      //         <td style={{ padding: "5px" }}></td>
      //         <td style={{ padding: "5px" }}></td>
      //         <td style={{ padding: "5px" }}></td>
      //         <td style={{ padding: "5px" }}></td>
      //         <td style={{ padding: "5px", color: "red", fontWeight: "bold" }}>
      //           {text.note}
      //         </td>
      //         <td style={{ padding: "5px" }}>{text.date}</td>
      //       </tr>
      //     </tbody>
      //   );
      // } 
      else {
        return (
          <tbody key={idx}>
            <tr className="tra">
              <td style={{ padding: "5px" }}>{text.userName}</td>
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px" }}></td>
              <td style={{ padding: "5px", color: "red", fontWeight: "bold" }}>
                {text.node2}
              </td>
              <td style={{ padding: "5px" }}>{text.date}</td>
            </tr>
          </tbody>
        );
      }
    });

    return (
      <div>
        <Navbars />
        <Container className="page">
          <Row>
            <Col>
              <h1 className="textHead">ตารางผู้เล่นเสือมังกร</h1>
            </Col>
          </Row>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  <span style={{ color: "white", fontSize: "20px" }}>
                    ผลแทง
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  <span style={{ color: "white", fontSize: "20px" }}>
                    สถิติผลการออก{" "}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "3",
                  })}
                  onClick={() => {
                    this.toggle("3");
                  }}
                >
                  <span style={{ color: "white", fontSize: "20px" }}>
                    ผลได้เสียประจำรอบ
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "4",
                  })}
                  onClick={() => {
                    this.toggle("4");
                  }}
                >
                  <span style={{ color: "white", fontSize: "20px" }}>
                    ยอดเงินสุทธิ
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div>
                  {this.state.loading1 === true ? (
                    <div>
                      <Row style={{ marginBottom: "25px", marginTop: "20px" }}>
                        <Label
                          className="textSub"
                          style={{ marginBottom: "0px" }}
                        >
                          ข้อความไปยังกลุ่ม
                        </Label>
                      </Row>
                      <Row>
                        <Label
                          className="textSub"
                          style={{ fontSize: "18px", marginRight: "50px" }}
                        >
                          เตือนแทงนอกเวลา
                        </Label>
                        <Input
                          type="text"
                          value={this.state.messageworning1}
                          style={{
                            width: "380px",
                            marginRight: "10px",
                            padding: "10px",
                          }}
                          placeholder="กรอกเลขลำดับเท่านั้น"
                          onChange={(e) =>
                            this.setState({
                              messageworning1: e.target.value,
                            })
                          }
                        ></Input>
                        <Button
                          style={{
                            height: "40px",
                            width: "15%",
                            marginLeft: "20px",
                            border: "2px solid #e7e7e7 ",
                          }}
                          color="danger"
                          onClick={this.sentwornningnotify1}
                        >
                          ส่ง
                        </Button>
                      </Row>
                      <Row>
                        <Label
                          className="textSub"
                          style={{ fontSize: "18px", marginRight: "125px" }}
                        >
                          ข้อความ
                        </Label>
                        <Input
                          style={{
                            width: "380px",
                            marginRight: "10px",
                            height: "100px",
                            padding: "10px",
                          }}
                          value={this.state.message}
                          type="textarea"
                          placeholder="ข้อความที่ต้องส่งไปยังกลุ่ม"
                          onChange={(e) =>
                            this.setState({
                              message: e.target.value,
                            })
                          }
                        ></Input>
                        <Button
                          style={{
                            height: "40px",
                            width: "15%",
                            marginLeft: "20px",
                            border: "2px solid #e7e7e7 ",
                          }}
                          color="success"
                          onClick={this.sentmessagenotify}
                        >
                          ส่งข้อความ
                        </Button>
                      </Row>
                      <Row style={{ marginTop: "20px" }}>
                        <Col>
                          <h1
                            style={{
                              fontWeight: "bolder",
                              color: "white",
                            }}
                          >
                            ผลแทง
                          </h1>
                        </Col>
                        <Col>
                          <Button
                            style={{
                              fontWeight: "bolder",
                              border: "2px solid #e7e7e7 ",
                            }}
                            color="dark"
                            onClick={this.toggle1}
                          >
                            ล้างข้อมูล
                          </Button>
                        </Col>
                      </Row>
                      <Modal isOpen={this.state.modal1} toggle={this.toggle1}>
                        <ModalHeader toggle={this.toggle1}>
                          ยืนยันการทำรายการ
                        </ModalHeader>
                        <ModalFooter>
                          <Button color="danger" onClick={this.toggle1}>
                            ยกเลิก
                          </Button>
                          <Button
                            color="primary"
                            onClick={async () => {
                              let app = Firebase.database().ref(`/dataTigerdargon/userInput/`)
                              await app.remove()
                              this.setState({ modal1: false });
                              window.location.reload();
                            }}
                          >
                            ยืนยัน
                          </Button>
                        </ModalFooter>
                      </Modal>
                      <table style={{ minWidth: '500px', maxWidth: "980px" }}>
                        <thead>
                          <tr className="theada">
                            <td style={{ padding: "8px" }}>UserID</td>
                            <td
                              style={{
                                backgroundColor: "blue",
                                color:"white"
                              }}
                            >
                              แทงเสือ
                            </td>
                            <td
                              style={{
                                backgroundColor: "red",
                                color:"white"
                              }}
                            >
                              แทงมังกร
                            </td>
                            <td
                              style={{
                                backgroundColor: "yellow",
                                color:"black"
                              }}
                            >
                              แทงคู่
                            </td>
                            <td
                              style={{
                                backgroundColor: "green",
                                color:"white"
                              }}
                            >
                              แทงไทเกมส์
                            </td>
                            <td className="tableMoney">จำนวนเงิน</td>
                            <td className="tableMoney">หมายเหตุ</td>
                            <td className="tableDtae">เวลา</td>
                          </tr>
                        </thead>
                        {newSort.reverse()}
                      </table>
                    </div>
                  ) : (
                      <div style={{ marginTop: "100px" }}>
                        <center>
                          <div style={{ marginBottom: "80px" }}>
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="primary"
                            />
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="secondary"
                            />
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="success"
                            />
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="danger"
                            />
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="warning"
                            />
                          </div>
                        </center>
                      </div>
                    )}
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div>
                  {this.state.loading1 === true ? (
                    <div>
                      <Row style={{ marginBottom: "25px" }}>
                        <Col sm={6}>
                          <h1
                            style={{
                              fontWeight: "bolder",
                              color: "white",
                              marginTop: "30px",
                            }}
                          >
                            สถิติผลการออก
                          </h1>
                        </Col>
                        <Col>
                          <Row>
                            <Button
                              onClick={this.toPng1}
                              style={{
                                marginTop: "30px",
                              }}
                              color="primary"
                            >
                              Download PNG
                          </Button>

                            <Button
                              style={{
                                marginLeft: "5px",
                                marginTop: "30px",
                                border: "1px solid #e7e7e7 ",
                                fontWeight: '16px'
                              }}
                              color="dark"
                              onClick={this.toggle2}
                            >
                              ลบสถิติ
                          </Button>
                          </Row>
                        </Col>
                        <Modal isOpen={this.state.modal2} toggle={this.toggle2}>
                          <ModalHeader toggle={this.toggle2}>
                            <a style={{ fontSize: '20px' }}>
                              ห้ามกด <a style={{ fontWeight: "bolder", color: 'red' }}>ยืนยัน</a> หากยังอยู่ในรอบเล่น <br></br>
                              <a style={{ fontWeight: "bolder", color: 'red' }}>เนื่องจากจะทำให้ระบบคำนวณเงินผิดพลาดได้ !!!</a>
                            </a>
                          </ModalHeader>
                          <ModalFooter>
                            <Button color="danger" onClick={this.toggle2}>
                              ยกเลิก
                          </Button>
                            <Button
                              color="primary"
                              onClick={async () => {
                                let app = Firebase.database().ref(`/dataTigerdargon/stat/`)
                                await app.remove()
                                let app1 = Firebase.database().ref(`/dataTigerdargon/concluResult/`)
                                await app1.remove()
                                this.setState({ modal2: false });
                              }}
                            >
                              ยืนยัน
                          </Button>
                          </ModalFooter>
                        </Modal>

                      </Row>
                      <table style={{ width: "580px" }} id="สถิติผลการออก">
                        <thead>
                          <tr className="theada">
                            <td style={{ padding: "8px" }}>รอบ</td>
                            <td className="namesub2"></td>
                            <td className="namesub2">ผลที่ออก</td>
                            <td style={{ padding: "8px" }}>เวลา</td>
                          </tr>
                        </thead>
                        {this.state.round.map((text, idx) => {
                          if (text.result === 1) {
                            let mes = 'เสือ'
                            return (
                              <tbody key={idx}>
                                <tr className="tra">
                                  <td style={{ padding: "5px" }}>
                                    {text.round}
                                  </td>
                                  <td style={{ padding: "5px" }}>
                                    {text.result1}
                                  </td>
                                  <td
                                    style={{
                                      padding: "5px",
                                      backgroundColor: "blue",
                                      color: 'white'
                                    }}
                                  >
                                    {mes}
                                  </td>
                                  <td style={{ padding: "5px" }}>
                                    {text.timeOpen}
                                  </td>
                                </tr>
                              </tbody>
                            );
                          }
                          if (text.result === 2) {
                            let mes = 'มังกร'
                            return (
                              <tbody key={idx}>
                                <tr className="tra">
                                  <td style={{ padding: "5px" }}>
                                    {text.round}
                                  </td>
                                  <td style={{ padding: "5px" }}>
                                    {text.result1}
                                  </td>
                                  <td
                                    style={{
                                      padding: "5px",
                                      backgroundColor: "red",
                                      color: 'white'
                                    }}
                                  >
                                    {mes}
                                  </td>
                                  <td style={{ padding: "5px" }}>
                                    {text.timeOpen}
                                  </td>
                                </tr>
                              </tbody>
                            );
                          }
                          if (text.result === 3) {
                            if (text.result3 === 'tiger') {
                              let mes = 'คู่ เสือ'
                              return (
                                <tbody key={idx}>
                                  <tr className="tra">
                                    <td style={{ padding: "5px" }}>
                                      {text.round}
                                    </td>
                                    <td style={{ padding: "5px" }}>
                                      {text.result1}
                                    </td>
                                    <td
                                      style={{
                                        padding: "5px",
                                        backgroundColor: "yellow",
                                      }}
                                    >
                                      {mes}
                                    </td>
                                    <td style={{ padding: "5px" }}>
                                      {text.timeOpen}
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            } else if (text.result3 === 'dragon') {
                              let mes = 'คู่ มังกร'
                              return (
                                <tbody key={idx}>
                                  <tr className="tra">
                                    <td style={{ padding: "5px" }}>
                                      {text.round}
                                    </td>
                                    <td style={{ padding: "5px" }}>
                                      {text.result1}
                                    </td>
                                    <td
                                      style={{
                                        padding: "5px",
                                        backgroundColor: "yellow",
                                        color: 'red'
                                      }}
                                    >
                                      {mes}
                                    </td>
                                    <td style={{ padding: "5px" }}>
                                      {text.timeOpen}
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            }


                          }
                          if (text.result === 4) {
                            let mes = 'ไทเกมส์'
                            return (
                              <tbody key={idx}>
                                <tr className="tra">
                                  <td style={{ padding: "5px" }}>
                                    {text.round}
                                  </td>
                                  <td style={{ padding: "5px" }}>
                                    {text.result1}
                                  </td>
                                  <td
                                    style={{
                                      padding: "5px",
                                      backgroundColor: "green",
                                      color: 'white'
                                    }}
                                  >
                                    {mes}
                                  </td>
                                  <td style={{ padding: "5px" }}>
                                    {text.timeOpen}
                                  </td>
                                </tr>
                              </tbody>
                            );
                          }
                        })}
                      </table>
                    </div>
                  ) : (
                      <div style={{ marginTop: "100px" }}>
                        <center>
                          <div style={{ marginBottom: "80px" }}>
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="primary"
                            />
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="secondary"
                            />
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="success"
                            />
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="danger"
                            />
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="warning"
                            />
                          </div>
                        </center>
                      </div>
                    )}
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div>
                  {this.state.loading2 === true ? (
                    <div>
                      <center>
                        <Input
                          style={{ marginTop: "20px" }}
                          className="sizeSearch"
                          type="text"
                          placeholder="ค้นหาเลขรอบ"
                          value={this.state.search}
                          onChange={this.updateSearch}
                        ></Input>
                      </center>
                      <Row style={{ marginBottom: "25px" }}>
                        <Col sm={5}>
                          <h1
                            style={{
                              fontWeight: "bolder",
                              color: "white",
                              marginTop: "30px",
                            }}
                          >
                            ผลได้เสียประจำรอบ
                          </h1>
                        </Col>
                        <Col>
                          <Button
                            onClick={this.toPng2}
                            style={{
                              marginTop: "30px",
                            }}
                            color="primary"
                          >
                            Download PNG
                          </Button>
                        </Col>
                        <Col>
                          {this.state.search !== "" ? (
                            <Button
                              onClick={async () => {

                                let app = Firebase.database().ref(`/dataTigerdargon/userInput/`)
                                await app.remove()

                                this.state.members.map((item1) => {
                                  console.log("item1", item1);
                                  this.state.results.map((item2) => {
                                    console.log("item2", item2);
                                    if (
                                      item1.idkey === item2.idkey &&
                                      item2.round === this.state.search
                                    ) {
                                      let app = Firebase.database().ref(
                                        `/dataMember/${item1.key}`
                                      );
                                      app.update({
                                        idkey: item1.idkey,
                                        money: item2.totalMoney,
                                        idkey: item1.idkey,
                                        userName: item1.userName,
                                      });
                                    }
                                  });
                                });
                              }}
                              style={{
                                marginTop: "30px",
                              }}
                              color="dark"
                            >
                              อัปเดทยอดเงิน
                            </Button>
                          ) : (
                              <p></p>
                            )}
                        </Col>
                      </Row>
                      <div id="ผลได้เสียประจำรอบ">
                        {filterRound.map((item, idx) => {
                          //const res = item.result1.split("");
                          if (Number(item.result) === 1) {
                            let mes = 'เสือ'
                            return (
                              <Row
                                key={idx}
                                style={{
                                  backgroundColor: "#c97dc0",
                                  width: "960px",
                                  padding: "25px",
                                  marginLeft: "0px",
                                }}
                              >
                                <Col
                                  xs={1}
                                  style={{
                                    backgroundColor: "black",
                                    height: "100px",
                                    padding: "10px",
                                  }}
                                >
                                  <h1
                                    style={{
                                      color: "white",
                                      fontSize: "18px",
                                      padding: "6px",
                                    }}
                                  >
                                    วันที่
                                  </h1>
                                  <h1
                                    style={{
                                      color: "white",
                                      fontSize: "18px",
                                      padding: "4px",
                                    }}
                                  >
                                    รอบที่
                                  </h1>
                                </Col>
                                <Col
                                  xs={2}
                                  style={{
                                    padding: "0px",
                                  }}
                                >
                                  <h1
                                    className="roundcol"
                                    style={{
                                      color: "black",
                                      fontSize: "18px",
                                      backgroundColor: "#F0F7D4",
                                      textAlign: "center",
                                      padding: "15px",
                                      width: "150px",
                                      height: "50px",
                                      marginBottom: "0px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {this.state.datestring}
                                  </h1>
                                  <h1
                                    className="roundcol"
                                    style={{
                                      color: "white",
                                      fontSize: "20px",
                                      backgroundColor: "#C21460",
                                      padding: "15px",
                                      width: "150px",
                                      height: "50px",
                                      marginBottom: "0px",
                                      textAlign: "center",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    {this.state.search}
                                  </h1>
                                </Col>

                                <Col
                                  xs={1}
                                  style={{ marginLeft: "180px" }}
                                ></Col>

                                <Col

                                  style={{
                                    padding: "0px",
                                  }}
                                >
                                  <Label
                                    style={{
                                      color: "#1A1110",
                                      fontSize: "18px",
                                      fontWeight: "bolder",
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    ผลที่ออก
                                  </Label>
                                  <span
                                    style={{
                                      color: "black",
                                      fontSize: "50px",
                                      backgroundColor: "blue",
                                      color: "white",
                                      paddingRight: "50px",
                                      paddingLeft: "50px",
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                      marginBottom: "0px",
                                      textAlign: "center",
                                      fontWeight: "bolder",
                                      borderRadius: "15px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    {mes}
                                  </span>

                                </Col>

                              </Row>

                            );
                          }

                          if (Number(item.result) === 2) {
                            let mes = 'มังกร'
                            return (
                              <Row
                                key={idx}
                                style={{
                                  backgroundColor: "#c97dc0",
                                  width: "960px",
                                  padding: "30px",
                                  marginLeft: "0px",
                                }}
                              >
                                <Col
                                  xs={1}
                                  style={{
                                    backgroundColor: "black",
                                    height: "100px",
                                    padding: "10px",
                                  }}
                                >
                                  <h1
                                    style={{
                                      color: "white",
                                      fontSize: "18px",
                                      padding: "6px",
                                    }}
                                  >
                                    วันที่
                                  </h1>
                                  <h1
                                    style={{
                                      color: "white",
                                      fontSize: "18px",
                                      padding: "4px",
                                    }}
                                  >
                                    รอบที่
                                  </h1>
                                </Col>
                                <Col
                                  xs={2}
                                  style={{
                                    padding: "0px",
                                  }}
                                >
                                  <h1
                                    className="roundcol"
                                    style={{
                                      color: "black",
                                      fontSize: "18px",
                                      backgroundColor: "#F0F7D4",
                                      textAlign: "center",
                                      padding: "15px",
                                      width: "150px",
                                      height: "50px",
                                      marginBottom: "0px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {this.state.datestring}
                                  </h1>
                                  <h1
                                    className="roundcol"
                                    style={{
                                      color: "white",
                                      fontSize: "20px",
                                      backgroundColor: "#C21460",
                                      padding: "15px",
                                      width: "150px",
                                      height: "50px",
                                      marginBottom: "0px",
                                      textAlign: "center",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    {this.state.search}
                                  </h1>
                                </Col>

                                <Col
                                  xs={1}
                                  style={{ marginLeft: "210px" }}
                                ></Col>
                                <Col

                                  style={{
                                    padding: "0px",
                                  }}
                                >
                                  <Label
                                    style={{
                                      color: "#1A1110",
                                      fontSize: "18px",
                                      fontWeight: "bolder",
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    ผลที่ออก
                                  </Label>
                                  <span
                                    style={{
                                      color: "black",
                                      fontSize: "50px",
                                      backgroundColor: "red",
                                      color: "white",
                                      paddingRight: "50px",
                                      paddingLeft: "50px",
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                      marginBottom: "0px",
                                      textAlign: "center",
                                      fontWeight: "bolder",
                                      borderRadius: "15px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    {mes}
                                  </span>

                                </Col>
                              </Row>
                            );
                          }

                          if (Number(item.result) === 3) {
                            console.log('item', item);
                            if (item.result3 === 'tiger') {
                              let mes = 'คู่ เสือ'
                              return (
                                <Row
                                  key={idx}
                                  style={{
                                    backgroundColor: "#c97dc0",
                                    width: "960px",
                                    padding: "30px",
                                    marginLeft: "0px",
                                  }}
                                >
                                  <Col
                                    xs={1}
                                    style={{
                                      backgroundColor: "black",
                                      height: "100px",
                                      padding: "10px",
                                    }}
                                  >
                                    <h1
                                      style={{
                                        color: "white",
                                        fontSize: "18px",
                                        padding: "6px",
                                      }}
                                    >
                                      วันที่
                                  </h1>
                                    <h1
                                      style={{
                                        color: "white",
                                        fontSize: "18px",
                                        padding: "4px",
                                      }}
                                    >
                                      รอบที่
                                  </h1>
                                  </Col>
                                  <Col
                                    xs={2}
                                    style={{
                                      padding: "0px",
                                    }}
                                  >
                                    <h1
                                      className="roundcol"
                                      style={{
                                        color: "black",
                                        fontSize: "18px",
                                        backgroundColor: "#F0F7D4",
                                        textAlign: "center",
                                        padding: "15px",
                                        width: "150px",
                                        height: "50px",
                                        marginBottom: "0px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.state.datestring}
                                    </h1>
                                    <h1
                                      className="roundcol"
                                      style={{
                                        color: "white",
                                        fontSize: "20px",
                                        backgroundColor: "#C21460",
                                        padding: "15px",
                                        width: "150px",
                                        height: "50px",
                                        marginBottom: "0px",
                                        textAlign: "center",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {this.state.search}
                                    </h1>
                                  </Col>

                                  <Col
                                    xs={1}
                                    style={{ marginLeft: "210px" }}
                                  ></Col>
                                  <Col

                                    style={{
                                      padding: "0px",
                                    }}
                                  >
                                    <Label
                                      style={{
                                        color: "#1A1110",
                                        fontSize: "18px",
                                        fontWeight: "bolder",
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      ผลที่ออก
                                  </Label>
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "50px",
                                        backgroundColor: "#FFFF00",
                                        paddingRight: "50px",
                                        paddingLeft: "50px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        marginBottom: "0px",
                                        textAlign: "center",
                                        fontWeight: "bolder",
                                        borderRadius: "15px",
                                        marginRight: "10px"
                                      }}
                                    >
                                      {mes}
                                    </span>

                                  </Col>
                                </Row>
                              );
                            }
                            if (item.result3 === 'dragon') {
                              let mes = 'คู่ มังกร '
                              return (
                                <Row
                                  key={idx}
                                  style={{
                                    backgroundColor: "#c97dc0",
                                    width: "960px",
                                    padding: "30px",
                                    marginLeft: "0px",
                                  }}
                                >
                                  <Col
                                    xs={1}
                                    style={{
                                      backgroundColor: "black",
                                      height: "100px",
                                      padding: "10px",
                                    }}
                                  >
                                    <h1
                                      style={{
                                        color: "white",
                                        fontSize: "18px",
                                        padding: "6px",
                                      }}
                                    >
                                      วันที่
                                  </h1>
                                    <h1
                                      style={{
                                        color: "white",
                                        fontSize: "18px",
                                        padding: "4px",
                                      }}
                                    >
                                      รอบที่
                                  </h1>
                                  </Col>
                                  <Col
                                    xs={2}
                                    style={{
                                      padding: "0px",
                                    }}
                                  >
                                    <h1
                                      className="roundcol"
                                      style={{
                                        color: "black",
                                        fontSize: "18px",
                                        backgroundColor: "#F0F7D4",
                                        textAlign: "center",
                                        padding: "15px",
                                        width: "150px",
                                        height: "50px",
                                        marginBottom: "0px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.state.datestring}
                                    </h1>
                                    <h1
                                      className="roundcol"
                                      style={{
                                        color: "white",
                                        fontSize: "20px",
                                        backgroundColor: "#C21460",
                                        padding: "15px",
                                        width: "150px",
                                        height: "50px",
                                        marginBottom: "0px",
                                        textAlign: "center",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {this.state.search}
                                    </h1>
                                  </Col>

                                  <Col
                                    xs={1}
                                    style={{ marginLeft: "210px" }}
                                  ></Col>
                                  <Col

                                    style={{
                                      padding: "0px",
                                    }}
                                  >
                                    <Label
                                      style={{
                                        color: "#1A1110",
                                        fontSize: "18px",
                                        fontWeight: "bolder",
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      ผลที่ออก
                                  </Label>
                                    <span
                                      style={{
                                        color: "black",
                                        fontSize: "50px",
                                        backgroundColor: "#FFFF00",
                                        paddingRight: "50px",
                                        paddingLeft: "50px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        marginBottom: "0px",
                                        textAlign: "center",
                                        fontWeight: "bolder",
                                        borderRadius: "15px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      {mes}
                                    </span>

                                  </Col>
                                </Row>
                              );
                            }


                          }
                          if (Number(item.result) === 4) {
                            let mes = 'ไทเกมส์'
                            return (
                              <Row
                                key={idx}
                                style={{
                                  backgroundColor: "#c97dc0",
                                  width: "960px",
                                  padding: "30px",
                                  marginLeft: "0px",
                                }}
                              >
                                <Col
                                  xs={1}
                                  style={{
                                    backgroundColor: "black",
                                    height: "100px",
                                    padding: "10px",
                                  }}
                                >
                                  <h1
                                    style={{
                                      color: "white",
                                      fontSize: "18px",
                                      padding: "6px",
                                    }}
                                  >
                                    วันที่
                                  </h1>
                                  <h1
                                    style={{
                                      color: "white",
                                      fontSize: "18px",
                                      padding: "4px",
                                    }}
                                  >
                                    รอบที่
                                  </h1>
                                </Col>
                                <Col
                                  xs={2}
                                  style={{
                                    padding: "0px",
                                  }}
                                >
                                  <h1
                                    className="roundcol"
                                    style={{
                                      color: "black",
                                      fontSize: "18px",
                                      backgroundColor: "#F0F7D4",
                                      textAlign: "center",
                                      padding: "15px",
                                      width: "150px",
                                      height: "50px",
                                      marginBottom: "0px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {this.state.datestring}
                                  </h1>
                                  <h1
                                    className="roundcol"
                                    style={{
                                      color: "white",
                                      fontSize: "20px",
                                      backgroundColor: "#C21460",
                                      padding: "15px",
                                      width: "150px",
                                      height: "50px",
                                      marginBottom: "0px",
                                      textAlign: "center",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    {this.state.search}
                                  </h1>
                                </Col>
                                <Col
                                  xs={1}
                                  style={{ marginLeft: "210px" }}
                                ></Col>
                                <Col
                                  style={{
                                    padding: "0px",
                                  }}
                                >
                                  <Label
                                    style={{
                                      color: "#1A1110",
                                      fontSize: "18px",
                                      fontWeight: "bolder",
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    ผลที่ออก
                                  </Label>
                                  <span
                                    style={{
                                      width: '50px',
                                      color: "black",
                                      fontSize: "50px",
                                      backgroundColor: "green",
                                      color: "white",
                                      paddingRight: "50px",
                                      paddingLeft: "50px",
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                      marginBottom: "0px",
                                      textAlign: "center",
                                      fontWeight: "bolder",
                                      borderRadius: "15px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    {mes}
                                  </span>

                                </Col>
                              </Row>
                            );
                          }
                        })}
                        <table style={{ width: "960px" }}>
                          <thead>
                            <tr className="theada">
                              <td style={{ width: "50px", }}>UserID</td>
                              <td className="namesub1">Username</td>
                              <td className="tabelMoney">ยอดเงินคงเหลือ</td>
                              <td style={{ width: "80px", backgroundColor: "blue", color: "white" }}>
                                เสือ
                              </td>
                              <td style={{ width: "80px", backgroundColor: "red", color: "white" }}>
                                มังกร
                              </td>
                              <td style={{ width: "80px", backgroundColor: "#FFFF00" }}>
                                คู่
                              </td>
                              <td style={{ width: "80px", backgroundColor: "green", color: "white" }}>
                                ไทเกมส์
                              </td>
                              <td style={{ width: "80px", }}>ได้</td>
                              {/* <td style={{ width: "80px", }}>เสีย</td> */}
                              <td className="tabelMoney">ยอดเงินสุทธิ</td>
                            </tr>
                          </thead>
                          {filterData.map((text, idx) => {
                            var nf = new Intl.NumberFormat();
                            console.log('data', text);


                            if (text.result3 === 'tiger') {

                              if (text.answer === 1) {
                                if (text.result2_1) {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            backgroundColor: "yellow",
                                            color: "ิblack"
                                          }}
                                        >
                                          {text.result2_1}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_2}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                } else {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_1}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_2}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                }
                              }

                            }
                            else if (text.result3 === 'dragon') {

                              if (text.answer === 1) {
                                if (text.result2_1) {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            backgroundColor: "yellow",
                                            color: "black"
                                          }}
                                        >
                                          {text.result2_1}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_2}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                } else {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_1}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_2}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                }
                              }

                              if (text.answer === 2) {
                                if (text.result2_2) {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_1}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            backgroundColor: "yellow",
                                            color: "black"
                                          }}
                                        >
                                          {text.result2_2}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                } else {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_1}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                          }}
                                        >
                                          {text.result2_2}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                }
                              }

                              if (text.answer === 3) {
                                if (text.result2_2) {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_1}
                                        </td>
                                        <td style={{
                                          padding: "5px",
                                          backgroundColor: "yellow",
                                          color: "black"
                                        }}>
                                          {text.result2_2}
                                        </td>
                                        <td
                                          style={{ padding: "5px" }}
                                        >
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );

                                }
                                if (text.result2_3) {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_1}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_2}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            backgroundColor: "yellow",
                                            color: "black"
                                          }}
                                        >
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                } else {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_1}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                          }}
                                        >
                                          {text.result2_2}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                }
                              }

                              if (text.answer === 4) {
                                if (text.result2_4) {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_1}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_2}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_3}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            backgroundColor: "yellow",
                                            color: "black"
                                          }}
                                        >
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                } else {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_1}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                          }}
                                        >
                                          {text.result2_2}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                }
                              }

                            }

                            if (text.answer === 2) {
                              if (text.result2_2) {
                                return (
                                  <tbody key={idx}>
                                    <tr className="tra">
                                      <td style={{ padding: "5px" }}>
                                        {text.userID}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.userName}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {nf.format(text.refMoney)}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.result2_1}
                                      </td>
                                      <td
                                        style={{
                                          padding: "5px",
                                          backgroundColor: "red",
                                          color: "white"
                                        }}
                                      >
                                        {text.result2_2}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.result2_3}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.result2_4}
                                      </td>
                                      <td
                                        style={{
                                          padding: "5px",
                                          color: "#28a745",
                                        }}
                                      >
                                        {nf.format(text.winMoney)}
                                      </td>
                                      {/* <td
                                        style={{
                                          padding: "5px",
                                          color: "#dc3545",
                                        }}
                                      >
                                        {nf.format(text.loseMoney)}
                                      </td> */}
                                      <td style={{ padding: "5px" }}>
                                        {nf.format(text.totalMoney)}
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              } else {
                                return (
                                  <tbody key={idx}>
                                    <tr className="tra">
                                      <td style={{ padding: "5px" }}>
                                        {text.userID}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.userName}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {nf.format(text.refMoney)}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.result2_1}
                                      </td>
                                      <td
                                        style={{
                                          padding: "5px",
                                        }}
                                      >
                                        {text.result2_2}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.result2_3}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.result2_4}
                                      </td>
                                      <td
                                        style={{
                                          padding: "5px",
                                          color: "#28a745",
                                        }}
                                      >
                                        {nf.format(text.winMoney)}
                                      </td>
                                      {/* <td
                                        style={{
                                          padding: "5px",
                                          color: "#dc3545",
                                        }}
                                      >
                                        {nf.format(text.loseMoney)}
                                      </td> */}
                                      <td style={{ padding: "5px" }}>
                                        {nf.format(text.totalMoney)}
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              }
                            }

                            if (text.answer === 3) {
                              if (text.result2_3 && text.result2_1) {
                                return (
                                  <tbody key={idx}>
                                    <tr className="tra">
                                      <td style={{ padding: "5px" }}>
                                        {text.userID}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.userName}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {nf.format(text.refMoney)}
                                      </td>
                                      <td style={{
                                        padding: "5px",
                                        backgroundColor: "#FFFF00"
                                      }}>
                                        {text.result2_1}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.result2_2}
                                      </td>
                                      <td
                                        style={{
                                          padding: "5px",
                                          backgroundColor: "#FFFF00"
                                        }}
                                      >
                                        {text.result2_3}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.result2_4}
                                      </td>
                                      <td
                                        style={{
                                          padding: "5px",
                                          color: "#28a745",
                                        }}
                                      >
                                        {nf.format(text.winMoney)}
                                      </td>
                                      {/* <td
                                        style={{
                                          padding: "5px",
                                          color: "#dc3545",
                                        }}
                                      >
                                        {nf.format(text.loseMoney)}
                                      </td> */}
                                      <td style={{ padding: "5px" }}>
                                        {nf.format(text.totalMoney)}
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              }
                              else {
                                if (text.result2_3) {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_1}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_2}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            backgroundColor: "#FFFF00",
                                          }}
                                        >
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                } else {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td style={{ padding: "5px", backgroundColor: '#FFFF00' }}>
                                          {text.result2_1}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                          }}
                                        >
                                          {text.result2_2}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                }
                              }
                            }

                            if (text.answer === 4) {
                              if (text.result2_4) {
                                return (
                                  <tbody key={idx}>
                                    <tr className="tra">
                                      <td style={{ padding: "5px" }}>
                                        {text.userID}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.userName}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {nf.format(text.refMoney)}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.result2_1}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.result2_2}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.result2_3}
                                      </td>
                                      <td
                                        style={{
                                          padding: "5px",
                                          backgroundColor: "green",
                                          color: "white"
                                        }}
                                      >
                                        {text.result2_4}
                                      </td>
                                      <td
                                        style={{
                                          padding: "5px",
                                          color: "#28a745",
                                        }}
                                      >
                                        {nf.format(text.winMoney)}
                                      </td>
                                      {/* <td
                                        style={{
                                          padding: "5px",
                                          color: "#dc3545",
                                        }}
                                      >
                                        {nf.format(text.loseMoney)}
                                      </td> */}
                                      <td style={{ padding: "5px" }}>
                                        {nf.format(text.totalMoney)}
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              } else {
                                return (
                                  <tbody key={idx}>
                                    <tr className="tra">
                                      <td style={{ padding: "5px" }}>
                                        {text.userID}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.userName}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {nf.format(text.refMoney)}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.result2_1}
                                      </td>
                                      <td
                                        style={{
                                          padding: "5px",
                                        }}
                                      >
                                        {text.result2_2}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.result2_3}
                                      </td>
                                      <td style={{ padding: "5px" }}>
                                        {text.result2_4}
                                      </td>
                                      <td
                                        style={{
                                          padding: "5px",
                                          color: "#28a745",
                                        }}
                                      >
                                        {nf.format(text.winMoney)}
                                      </td>
                                      {/* <td
                                        style={{
                                          padding: "5px",
                                          color: "#dc3545",
                                        }}
                                      >
                                        {nf.format(text.loseMoney)}
                                      </td> */}
                                      <td style={{ padding: "5px" }}>
                                        {nf.format(text.totalMoney)}
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              }
                            }

                            else {
                              console.log('ไม่เข้าเงื่อนไขอะไรเลย', text);
                              if (text.answer === 1) {
                                if (text.result2_1) {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            backgroundColor: "blue",
                                            color: "white"
                                          }}
                                        >
                                          {text.result2_1}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_2}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                } else {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_1}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_2}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                }
                              }

                              if (text.answer === 2) {
                                if (text.result2_3 && text.result2_2) {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_1}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            backgroundColor: "green",
                                          }}
                                        >
                                          {text.result2_2}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  )
                                }
                                if (text.result2_2) {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_1}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            backgroundColor: "green",
                                          }}
                                        >
                                          {text.result2_2}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                } else {
                                  if (text.result3 === 'tiger') {
                                    return (
                                      <tbody key={idx}>
                                        <tr className="tra">
                                          <td style={{ padding: "5px" }}>
                                            {text.userID}
                                          </td>
                                          <td style={{ padding: "5px" }}>
                                            {text.userName}
                                          </td>
                                          <td style={{ padding: "5px" }}>
                                            {nf.format(text.refMoney)}
                                          </td>
                                          <td style={{ padding: "5px" }}>
                                            {text.result2_1}
                                          </td>
                                          <td
                                            style={{
                                              padding: "5px",
                                              backgroundColor: "red",
                                            }}
                                          >
                                            {text.result2_2}
                                          </td>
                                          <td style={{ padding: "5px" }}>
                                            {text.result2_3}
                                          </td>
                                          <td style={{ padding: "5px" }}>
                                            {text.result2_4}
                                          </td>
                                          <td
                                            style={{
                                              padding: "5px",
                                              color: "#28a745",
                                            }}
                                          >
                                            {nf.format(text.winMoney)}
                                          </td>
                                          {/* <td
                                            style={{
                                              padding: "5px",
                                              color: "#dc3545",
                                            }}
                                          >
                                            {nf.format(text.loseMoney)}
                                          </td> */}
                                          <td style={{ padding: "5px" }}>
                                            {nf.format(text.totalMoney)}
                                          </td>
                                        </tr>
                                      </tbody>
                                    );
                                  }
                                  else if (text.result3 === 'dragon') {
                                    return (
                                      <tbody key={idx}>
                                        <tr className="tra">
                                          <td style={{ padding: "5px" }}>
                                            {text.userID}
                                          </td>
                                          <td style={{ padding: "5px" }}>
                                            {text.userName}
                                          </td>
                                          <td style={{ padding: "5px" }}>
                                            {nf.format(text.refMoney)}
                                          </td>
                                          <td style={{ padding: "5px" }}>
                                            {text.result2_1}
                                          </td>
                                          <td
                                            style={{
                                              padding: "5px",
                                              backgroundColor: "red",
                                            }}
                                          >
                                            {text.result2_2}
                                          </td>
                                          <td style={{ padding: "5px" }}>
                                            {text.result2_3}
                                          </td>
                                          <td style={{ padding: "5px" }}>
                                            {text.result2_4}
                                          </td>
                                          <td
                                            style={{
                                              padding: "5px",
                                              color: "#28a745",
                                            }}
                                          >
                                            {nf.format(text.winMoney)}
                                          </td>
                                          {/* <td
                                            style={{
                                              padding: "5px",
                                              color: "#dc3545",
                                            }}
                                          >
                                            {nf.format(text.loseMoney)}
                                          </td> */}
                                          <td style={{ padding: "5px" }}>
                                            {nf.format(text.totalMoney)}
                                          </td>
                                        </tr>
                                      </tbody>
                                    );
                                  }
                                  else {
                                    return (
                                      <tbody key={idx}>
                                        <tr className="tra">
                                          <td style={{ padding: "5px" }}>
                                            {text.userID}
                                          </td>
                                          <td style={{ padding: "5px" }}>
                                            {text.userName}
                                          </td>
                                          <td style={{ padding: "5px" }}>
                                            {nf.format(text.refMoney)}
                                          </td>
                                          <td style={{ padding: "5px" }}>
                                            {text.result2_1}
                                          </td>
                                          <td
                                            style={{
                                              padding: "5px",
                                            }}
                                          >
                                            {text.result2_2}
                                          </td>
                                          <td style={{ padding: "5px" }}>
                                            {text.result2_3}
                                          </td>
                                          <td style={{ padding: "5px" }}>
                                            {text.result2_4}
                                          </td>
                                          <td
                                            style={{
                                              padding: "5px",
                                              color: "#28a745",
                                            }}
                                          >
                                            {nf.format(text.winMoney)}
                                          </td>
                                          {/* <td
                                            style={{
                                              padding: "5px",
                                              color: "#dc3545",
                                            }}
                                          >
                                            {nf.format(text.loseMoney)}
                                          </td> */}
                                          <td style={{ padding: "5px" }}>
                                            {nf.format(text.totalMoney)}
                                          </td>
                                        </tr>
                                      </tbody>
                                    );
                                  }

                                }
                              }

                              if (text.answer === 3) {
                                if (text.result2_3) {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_1}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_2}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            backgroundColor: "#FFFF00",
                                          }}
                                        >
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                } else {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_1}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                          }}
                                        >
                                          {text.result2_2}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                }
                              }
                              if (text.answer === 4) {
                                if (text.result2_4) {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_1}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_2}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_3}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            backgroundColor: "#FF0000",
                                          }}
                                        >
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                } else {
                                  return (
                                    <tbody key={idx}>
                                      <tr className="tra">
                                        <td style={{ padding: "5px" }}>
                                          {text.userID}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.userName}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.refMoney)}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_1}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                          }}
                                        >
                                          {text.result2_2}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_3}
                                        </td>
                                        <td style={{ padding: "5px" }}>
                                          {text.result2_4}
                                        </td>
                                        <td
                                          style={{
                                            padding: "5px",
                                            color: "#28a745",
                                          }}
                                        >
                                          {nf.format(text.winMoney)}
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "5px",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {nf.format(text.loseMoney)}
                                        </td> */}
                                        <td style={{ padding: "5px" }}>
                                          {nf.format(text.totalMoney)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                }
                              }
                            }


                          })}
                        </table>
                      </div>
                    </div>
                  ) : (
                      <div style={{ marginTop: "100px" }}>
                        <center>
                          <div style={{ marginBottom: "80px" }}>
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="primary"
                            />
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="secondary"
                            />
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="success"
                            />
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="danger"
                            />
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="warning"
                            />
                          </div>
                        </center>
                      </div>
                    )}
                </div>
              </TabPane>
              <TabPane tabId="4">
                <div>
                  {this.state.loading3 === true ? (
                    <div>
                      <Row style={{ marginBottom: "25px" }}>
                        <Col sm={5}>
                          <h1
                            style={{
                              fontWeight: "bolder",
                              color: "white",
                              marginTop: "30px",
                            }}
                          >
                            ยอดเงินสุทธิ
                          </h1>
                        </Col>
                        <Col>
                          <Button
                            onClick={this.toPng3}
                            style={{
                              marginTop: "30px",
                            }}
                            color="primary"
                          >
                            Download PNG
                          </Button>
                        </Col>
                      </Row>
                      <table style={{ width: "400px" }} id="ยอดเงินสุทธิ">
                        <thead>
                          <tr className="theada">
                            <td style={{ padding: "8px" }}>UserID</td>
                            <td>Username</td>
                            <td>ยอดเงินสุทธิ</td>
                          </tr>
                        </thead>
                        {this.state.members.map((text, idx) => {
                          var nf = new Intl.NumberFormat();
                          if (text.money != 0) {
                            return (
                              <tbody key={idx}>
                                <tr className="tra">
                                  <td style={{ padding: "5px" }}>
                                    {text.userID}
                                  </td>
                                  <td style={{ padding: "5px" }}>
                                    {text.userName}
                                  </td>
                                  <td style={{ padding: "5px" }}>
                                    {nf.format(text.money)}
                                  </td>
                                </tr>
                              </tbody>
                            );
                          }
                        })}
                      </table>
                    </div>
                  ) : (
                      <div style={{ marginTop: "100px" }}>
                        <center>
                          <div style={{ marginBottom: "80px" }}>
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="primary"
                            />
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="secondary"
                            />
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="success"
                            />
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="danger"
                            />
                            <Spinner
                              className="spin"
                              size="md"
                              type="grow"
                              color="warning"
                            />
                          </div>
                        </center>
                      </div>
                    )}
                </div>
              </TabPane>
            </TabContent>
          </div>
        </Container>
      </div >
    );
  }
}

export default TableTiger;
