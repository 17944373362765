import React, { Component } from "react";
import { Container } from "reactstrap";
import "../home.css";
import InputRef from "./InputRef";
import Navbar from "../Navbar/Navbars";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Navbar />
        <Container className="page">
          <h1 className="textHead">ควบคุมเสือมังกร</h1>
          <div>
            <InputRef />
          </div>
        </Container>
      </div>
    );
  }
}

export default Home;
