import React from 'react'
import { Container, Card, FormGroup, Form, Label, Col, Row, CardFooter, Button, Input } from 'reactstrap'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const Login = () => {
    const [inputcheck, setInputcheck] = useState(false)
    const [color, setColor] = useState('secondary')
    const [loginlog, setLoginlog] = useState({
        id: '',
        pass: ''
    })

    let history = useHistory();

    useEffect(() => {
        if (loginlog.id !== '' && loginlog.pass !== '') {
            setInputcheck(true)
            if (loginlog.id.length > 0 && loginlog.pass.length > 0) {
                console.log('id : ', loginlog.id.length)
                console.log('pass : ', loginlog.pass.length)
            }
            if (inputcheck === true) {
                setColor('success')
            }
        } else {
            setColor('secondary')
        }
        console.log('inputcheck : ', inputcheck);
    }, [loginlog])

    return (
        <Container style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '100px'
        }} >

            <h1 style={{
                color: 'white',
                margin: '50px'
            }}>เข้าสู่ระบบ HouseKK1</h1>
            <Card style={{
                minWidth: '45%',
                maxWidth: '60%',
                padding: '10px'
            }}>
                <FormGroup Row>
                    <Col>
                        <Label style={{ margin: '0', marginTop: '10px' }}> USERNAME </Label>
                        <Input
                            value={loginlog.id}
                            onChange={(e) => {
                                setLoginlog({ ...loginlog, id: (e.target.value) })
                            }}></Input>
                    </Col>
                    <Col>
                        <Label style={{ margin: '0', marginTop: '10px' }} > PASSWORD </Label>
                        <Input
                            type="password"
                            value={loginlog.pass}
                            onChange={(e) => {
                                setLoginlog({ ...loginlog, pass: (e.target.value) })
                            }}></Input>
                    </Col>
                </FormGroup>
                <CardFooter>
                    <Button color={color}
                        onClick={async () => {
                            if (color === 'success') {
                                console.log(" can login ");
                                if (loginlog.id === 'admin' && loginlog.pass === 'housekk1') {
                                    let adminlog = loginlog
                                    localStorage.setItem('admintest', JSON.stringify(adminlog))
                                    let test = localStorage.getItem('admintest')
                                    console.log(test)
                                    alert('เข้าสู่ระบบเรียบร้อย')
                                    history.push('/')
                                }
                                else {
                                    alert('ไม่สามารถเข้าสู่ระบบได้ กรุณาตรวจสอบ username password')
                                }
                            }
                            else {
                                console.log(" can't login ");
                                alert('กรอกข้อมูลให้ครบถ้วน')
                            }
                        }}>เข้าสู่ระบบ</Button>
                </CardFooter>
            </Card >
        </Container >

    )
}
export default Login